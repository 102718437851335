import { useShowError } from "@/hooks/notification";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { create, BlueprintCreateRequest } from "@/api/blueprint";
import React from "react";
import { useRouter } from "next/router";
import HomeIcon from "@mui/icons-material/Home";
import Image from "next/image";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ReportPatternSelection: React.FC = () => {
  const showError = useShowError();
  const isMobile = useMediaQuery("(max-width:600px)");
  const router = useRouter();

  const handleCreateGeneration = React.useCallback(async () => {
    try {
      const params: BlueprintCreateRequest = {
        title: "タイトルなし",
        pattern: "generation",
        prompt: "",
        paste_text: "",
        format: "standard",
        character_length: 600,
        language: "japanese",
        purpose: "大学のレポート作成",
        tone: "professional",
        quality: "intermediate",
        file_ids: [],
      };
      const response = await create(params);
      router.push(`/blueprint/${response.id}`);
    } catch (err) {
      showError(err);
    }
  }, [showError, router]);

  return (
    <>
      <Box sx={{ pt: 1 }}>
        <IconButton
          onClick={() => {
            router.push("/home");
          }}
          sx={{
            background: "#fff",
            px: 1,
            py: 0.5,
            ml: 1,
            justifyContent: "flex-start",
            boxShadow: 1,
            "&.MuiButtonBase-root": {
              borderRadius: 1,
            },
            ":hover": { background: "#f2eded" },
          }}
        >
          <HomeIcon sx={{ fontSize: 18, mr: 0.5 }} />
          <Typography sx={{ fontSize: 13 }}>ホーム</Typography>
        </IconButton>

        {isMobile ? (
          <>
            <Box sx={{ pt: 5 }}>
              <Typography
                sx={{ textAlign: "center", fontSize: 20, fontWeight: 600 }}
              >
                どの方法でレポートを作成しますか？
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "rgba(37, 37, 37, 0.70)",
                  mt: 1,
                }}
              >
                3つのフォーマットから選択
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 3,
                }}
              >
                <Box
                  component="button"
                  onClick={() => router.push("/report/paste")}
                  sx={{
                    cursor: "pointer",
                    boxShadow: 3,
                    border: "1px solid #d7d6d1",
                    borderRadius: 0.5,
                    height: 122,
                    p: 0,
                    mx: 2,
                    my: 1,
                    ":hover": { boxShadow: 5 },
                    ":active": {
                      boxShadow: 10,
                      transform: "translateY(2px)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <Image
                      src={"/images/paste_pattern.svg"}
                      alt={"PasteIcon"}
                      width={145}
                      height={120}
                    />
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        py: 1,
                        mt: 0,
                        px: 1.5,
                        textAlign: "left",
                      }}
                    >
                      <Typography sx={{ fontSize: 15, fontWeight: 600, mt: 1 }}>
                        任意のテキストから作成
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(37, 37, 37, 0.70)",
                          fontSize: 11,
                          mt: 1,
                        }}
                      >
                        メモ、授業の内容などの任意のテキストからレポートを作成
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  component="button"
                  onClick={handleCreateGeneration}
                  sx={{
                    cursor: "pointer",
                    boxShadow: 3,
                    border: "1px solid #d7d6d1",
                    borderRadius: 0.5,
                    height: 122,
                    p: 0,
                    mx: 2,
                    my: 1,
                    ":hover": { boxShadow: 5 },
                    ":active": {
                      boxShadow: 10,
                      transform: "translateY(2px)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <Image
                      src={"/images/prompt_pattern.svg"}
                      alt={"PromptIcon"}
                      width={145}
                      height={120}
                    />
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        py: 1,
                        mt: 0,
                        px: 1.5,
                        textAlign: "left",
                      }}
                    >
                      <Typography sx={{ fontSize: 15, fontWeight: 600, mt: 1 }}>
                        一行のプロンプトから作成
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(37, 37, 37, 0.70)",
                          fontSize: 11,
                          mt: 1,
                        }}
                      >
                        簡単なプロンプト（指示文）からレポートを作成
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  component="button"
                  onClick={() => router.push("/report/import")}
                  sx={{
                    cursor: "pointer",
                    boxShadow: 3,
                    border: "1px solid #d7d6d1",
                    borderRadius: 0.5,
                    height: 122,
                    p: 0,
                    mx: 2,
                    my: 1,
                    ":hover": { boxShadow: 5 },
                    ":active": {
                      boxShadow: 10,
                      transform: "translateY(2px)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <Image
                      src={"/images/upload_pattern.svg"}
                      alt={"UploadIcon"}
                      width={145}
                      height={120}
                    />
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        py: 1,
                        mt: 0,
                        px: 1.5,
                        textAlign: "left",
                      }}
                    >
                      <Typography sx={{ fontSize: 15, fontWeight: 600, mt: 1 }}>
                        任意のファイルから作成
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(37, 37, 37, 0.70)",
                          fontSize: 11,
                          mt: 1,
                        }}
                      >
                        授業資料（PDF、pptx、csv、zip、docx）などからレポートを作成
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ pt: 5 }}>
              <Typography
                sx={{ textAlign: "center", fontSize: 30, fontWeight: 600 }}
              >
                どの方法でレポートを作成しますか？
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 15,
                  color: "rgba(37, 37, 37, 0.70)",
                  mt: 2,
                }}
              >
                3つのフォーマットから選択
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 0.5,
                  mt: 6,
                }}
              >
                <Box
                  component="button"
                  onClick={() => router.push("/report/paste")}
                  sx={{
                    cursor: "pointer",
                    boxShadow: 3,
                    border: "1px solid #d7d6d1",
                    borderRadius: 0.5,
                    width: 282,
                    p: 0,
                    mx: 2,
                    ":hover": { boxShadow: 5 },
                    ":active": {
                      boxShadow: 10,
                      transform: "translateY(2px)",
                    },
                  }}
                >
                  <Box sx={{ background: "white", borderRadius: 0.5 }}>
                    <Image
                      src={"/images/paste_pattern.svg"}
                      alt={"PasteIcon"}
                      width={280}
                      height={230}
                    />
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        py: 1,
                        mt: 0,
                        px: 1.5,
                        textAlign: "left",
                      }}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                        任意のテキストから作成
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(37, 37, 37, 0.70)",
                          fontSize: 13,
                          mt: 1,
                        }}
                      >
                        メモ、授業の内容などの任意のテキストからレポートを作成
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          color: "rgba(37, 37, 37, 0.70)",
                          mr: 1,
                        }}
                      >
                        <ArrowForwardIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  component="button"
                  onClick={handleCreateGeneration}
                  sx={{
                    cursor: "pointer",
                    boxShadow: 3,
                    border: "1px solid #d7d6d1",
                    borderRadius: 0.5,
                    width: 282,
                    p: 0,
                    mx: 2,
                    ":hover": { boxShadow: 5 },
                    ":active": {
                      boxShadow: 10,
                      transform: "translateY(2px)",
                    },
                  }}
                >
                  <Box sx={{ background: "white" }}>
                    <Image
                      src={"/images/prompt_pattern.svg"}
                      alt={"PromptIcon"}
                      width={280}
                      height={230}
                    />
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        py: 1,
                        mt: 0,
                        px: 1.5,
                        textAlign: "left",
                      }}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                        一行のプロンプトから作成
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(37, 37, 37, 0.70)",
                          fontSize: 13,
                          mt: 1,
                        }}
                      >
                        簡単なプロンプト（指示文）からレポートを作成
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          color: "rgba(37, 37, 37, 0.70)",
                          mr: 1,
                        }}
                      >
                        <ArrowForwardIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  component="button"
                  onClick={() => router.push("/report/import")}
                  sx={{
                    cursor: "pointer",
                    boxShadow: 3,
                    border: "1px solid #d7d6d1",
                    borderRadius: 0.5,
                    width: 282,
                    p: 0,
                    mx: 2,
                    ":hover": { boxShadow: 5 },
                    ":active": {
                      boxShadow: 10,
                      transform: "translateY(2px)",
                    },
                  }}
                >
                  <Box sx={{ background: "white" }}>
                    <Image
                      src={"/images/upload_pattern.svg"}
                      alt={"UploadIcon"}
                      width={280}
                      height={230}
                    />
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        py: 1,
                        mt: 0,
                        px: 1.5,
                        textAlign: "left",
                      }}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                        任意のファイルから作成
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(37, 37, 37, 0.70)",
                          fontSize: 13,
                          mt: 1,
                        }}
                      >
                        授業資料（PDF、pptx、csv、zip、docx）などからレポートを作成
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          color: "rgba(37, 37, 37, 0.70)",
                          mr: 1,
                        }}
                      >
                        <ArrowForwardIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ReportPatternSelection;
