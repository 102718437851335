import { useCurrentUserState } from "@/hooks/user";
import ReportPatternSelection from "@/organisms/ReportPatternSelection";
import { Box } from "@mui/material";
import React from "react";

const TopPage: React.FC = () => {
  const currentUserState = useCurrentUserState();
  if (currentUserState == "idle") return <></>;

  return (
    <>
      <Box sx={{}}>
        <ReportPatternSelection />
      </Box>
    </>
  );
};

export default TopPage;
